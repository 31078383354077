<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="buys">
      <b-button
        v-if="role == 'admin'"
        class="returnOrder"
        @click="movement('compras')"
        >Compras</b-button
      >
      <b-button class="waitAddress" @click="movement('histórico')"
        >Compras feitas</b-button
      >
      <b-button class="waitAddress" @click="openPageInNewTab()"
        >Relatório</b-button
      >
    </div>
    <br />
    <div v-if="page == 'compras'">
      <h1 style="text-align: center">Comprar</h1>
      <h5 style="text-align: center">
        Última atualização ({{ splitDate(date) }})
      </h5>
      <div style="margin: 10px 0; text-align: center">
        <!-- Botões de Bloqueio -->
        <button
          class="btn btn-danger"
          @click="confirmPendentBatch(true, selectedProducts, 'Bloqueio')"
        >
          Bloquear Selecionados
        </button>
        <button
          class="btn btn-warning"
          @click="confirmPendentBatch(true, selectedProducts, 'Nova Engel')"
        >
          Bloquear Nova Engel
        </button>
      </div>
      <table>
        <tr>
          <th>
            SKU <br />
            <button
              style="text-align: center"
              class="btn btn-success"
              v-b-modal.add-prod
            >
              ➕
            </button>
            <button
              style="text-align: center"
              class="btn btn-warning"
              v-b-modal.create-prod
            >
              ➕
            </button>
          </th>
          <th>Nome</th>
          <th>Em falta</th>
          <th>
            <div>
              Alvim <br /><button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('Farmácia Alvim')"
              >
                🛒
              </button>
            </div>
          </th>
          <th>
            <div>
              Gomes <br /><button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('Farmácia Gomes')"
              >
                🛒
              </button>
            </div>
          </th>
          <th>
            <div>
              Perelhal <br /><button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('Farmácia Perelhal')"
              >
                🛒
              </button>
            </div>
          </th>
          <th>
            <div>
              Martins <br /><button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('Farmácia Martins')"
              >
                🛒
              </button>
            </div>
          </th>
          <th>
            <div>
              OCP<br /><button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('OCP')"
              >
                🛒
              </button>
            </div>
          </th>
          <th>
            <div>
              Nova Engel<br />
              <button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('Nova Engel')"
              >
                🛒
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="downCSVNova()"
              >
                ⏬
              </button>
            </div>
          </th>
          <th>
            <div>
              Marcas<br /><button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('marcas')"
              >
                🛒
              </button>
            </div>
          </th>
          <th style="min-width: 150px">
            <div>
              Externos <br /><button
                type="button"
                class="btn btn-primary"
                @click="confirmModal('extern')"
              >
                🛒
              </button>
              <button class="btn btn-success" v-b-modal.add-supplier>➕</button>
            </div>
          </th>
        </tr>
        <tr v-for="product in productsMissing" :key="product.sku">
          <td>
            <input
              type="checkbox"
              v-model="selectedProducts"
              :value="product.sku"
            />
            <br />
            <a id="openArticle" @click="passProductData(product, true)">{{
              product.sku
            }}</a>
            <br />
            {{ product.ean }}
            <p v-if="product.date_order != undefined" style="font-size: 90%">
              <span
                v-if="calculateDifferenceBetweenDates(product.date_order) == 2"
                style="background: blue; color: white"
              >
                {{ splitDate2(product.date_order) }}
              </span>
              <span
                v-else-if="
                  calculateDifferenceBetweenDates(product.date_order) == 3
                "
                style="background: orange; color: white"
              >
                {{ splitDate2(product.date_order) }}
              </span>
              <span
                v-else-if="
                  calculateDifferenceBetweenDates(product.date_order) == 4
                "
                style="background: red; color: white"
              >
                {{ splitDate2(product.date_order) }}
              </span>
              <span
                v-else-if="
                  calculateDifferenceBetweenDates(product.date_order) > 4
                "
                style="background: black; color: white"
              >
                {{ splitDate2(product.date_order) }}
              </span>
              <span v-else style="background: white">
                {{ splitDate2(product.date_order) }}
              </span>
            </p>
          </td>
          <td>
            {{ product.name }}
            <br />
            ({{ product.price_eur }} €)
            <br />
            <a
              id="setWaiting"
              @click="confirmPendent(true, product.sku, 'Nova Engel')"
              >Bloquear Produto Nova Engel</a
            >
            <br />
            <a id="setPendent" @click="confirmPendent(true, product.sku)"
              >Bloquear Produto</a
            >
          </td>
          <td>
            <!-- VERIFICAR SE TOTAL EXTERN ESTÁ PREENCHIDO -->

            <span
              v-if="
                Number(product.missing) -
                  Number(product.farma1.Total) -
                  Number(product.farma2.Total) -
                  Number(product.farma3.Total) -
                  Number(product.farma4.Total) -
                  Number(product.OCP.Total) -
                  Number(product.externTotal) -
                  Number(product.engel.Total) -
                  Number(product.marcaTotal) >=
                0
              "
            >
              {{
                Number(product.missing) -
                Number(product.farma1.Total) -
                Number(product.farma2.Total) -
                Number(product.farma3.Total) -
                Number(product.farma4.Total) -
                Number(product.OCP.Total) -
                Number(product.engel.Total) -
                Number(product.externTotal) -
                Number(product.marcaTotal)
              }}
            </span>
            <span v-else style="color: red">
              {{
                Number(product.missing) -
                Number(product.farma1.Total) -
                Number(product.farma2.Total) -
                Number(product.farma3.Total) -
                Number(product.farma4.Total) -
                Number(product.OCP.Total) -
                Number(product.engel.Total) -
                Number(product.externTotal) -
                Number(product.marcaTotal)
              }}
            </span>
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              v-model="product.farma1.Total"
              :min="0"
              :max="product.farma1.Stock"
              :disabled="
                Number(product.farma1.Stock) <= 0 ||
                product.farma1.Stock == 'Indisponível'
              "
              @change="
                updateFarma1(
                  product.farma1.Total,
                  product.name,
                  product.sku,
                  product.ean,
                  product.id_prod,
                  product.farma1.Preco
                )
              "
            />
            <p
              v-if="
                product.farma1.Stock <= 0 ||
                product.farma1.Stock == 'Indisponível'
              "
            >
              <span style="color: red">{{ product.farma1.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma1.Stock_Pendent != '0'"
              >
                - {{ product.farma1.Stock_Pendent }}</span
              >
              - {{ product.farma1.Preco }} €
            </p>
            <p
              v-else-if="product.farma1.Stock > 0 && product.farma1.Stock <= 5"
            >
              <span style="color: #eead2d">{{ product.farma1.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma1.Stock_Pendent != '0'"
              >
                - {{ product.farma1.Stock_Pendent }}</span
              >
              - {{ product.farma1.Preco }} €
            </p>
            <p v-else-if="product.farma1.Stock > 5">
              <span style="color: green">{{ product.farma1.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma1.Stock_Pendent != '0'"
              >
                - {{ product.farma1.Stock_Pendent }}</span
              >
              - {{ product.farma1.Preco }} €
            </p>
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              v-model="product.farma2.Total"
              :min="0"
              :max="product.farma2.Stock"
              :disabled="
                Number(product.farma2.Stock) <= 0 ||
                product.farma2.Stock == 'Indisponível'
              "
              @change="
                updateFarma2(
                  product.farma2.Total,
                  product.name,
                  product.sku,
                  product.ean,
                  product.id_prod,
                  product.farma2.Preco
                )
              "
            />
            <p
              v-if="
                product.farma2.Stock <= 0 ||
                product.farma2.Stock == 'Indisponível'
              "
            >
              <span style="color: red">{{ product.farma2.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma2.Stock_Pendent != '0'"
              >
                - {{ product.farma2.Stock_Pendent }}</span
              >
              - {{ product.farma2.Preco }} €
            </p>
            <p
              v-else-if="product.farma2.Stock > 0 && product.farma2.Stock <= 5"
            >
              <span style="color: #eead2d">{{ product.farma2.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma2.Stock_Pendent != '0'"
              >
                - {{ product.farma2.Stock_Pendent }}</span
              >
              - {{ product.farma2.Preco }} €
            </p>
            <p v-else-if="product.farma2.Stock > 5">
              <span style="color: green">{{ product.farma2.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma2.Stock_Pendent != '0'"
              >
                - {{ product.farma2.Stock_Pendent }}</span
              >
              - {{ product.farma2.Preco }} €
            </p>
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              v-model="product.farma3.Total"
              :min="0"
              :max="product.farma3.Stock"
              :disabled="
                Number(product.farma3.Stock) <= 0 ||
                product.farma3.Stock == 'Indisponível'
              "
              @change="
                updateFarma3(
                  product.farma3.Total,
                  product.name,
                  product.sku,
                  product.ean,
                  product.id_prod,
                  product.farma3.Preco
                )
              "
            />
            <p
              v-if="
                product.farma3.Stock <= 0 ||
                product.farma3.Stock == 'Indisponível'
              "
            >
              <span style="color: red">{{ product.farma3.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma3.Stock_Pendent != '0'"
              >
                - {{ product.farma3.Stock_Pendent }}</span
              >
              - {{ product.farma3.Preco }} €
            </p>
            <p
              v-else-if="product.farma3.Stock > 0 && product.farma3.Stock <= 5"
            >
              <span style="color: #eead2d">{{ product.farma3.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma3.Stock_Pendent != '0'"
              >
                - {{ product.farma3.Stock_Pendent }}</span
              >
              - {{ product.farma3.Preco }} €
            </p>
            <p v-else-if="product.farma3.Stock > 5">
              <span style="color: green">{{ product.farma3.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma3.Stock_Pendent != '0'"
              >
                - {{ product.farma3.Stock_Pendent }}</span
              >
              - {{ product.farma3.Preco }} €
            </p>
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              v-model="product.farma4.Total"
              :min="0"
              :max="product.farma4.Stock"
              :disabled="
                Number(product.farma4.Stock) <= 0 ||
                product.farma4.Stock == 'Indisponível'
              "
              @change="
                updateFarma4(
                  product.farma4.Total,
                  product.name,
                  product.sku,
                  product.ean,
                  product.id_prod,
                  product.farma4.Preco
                )
              "
            />
            <p
              v-if="
                product.farma4.Stock <= 0 ||
                product.farma4.Stock == 'Indisponível'
              "
            >
              <span style="color: red">{{ product.farma4.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma4.Stock_Pendent != '0'"
              >
                - {{ product.farma4.Stock_Pendent }}</span
              >
              - {{ product.farma4.Preco }} €
            </p>
            <p
              v-else-if="product.farma4.Stock > 0 && product.farma4.Stock <= 5"
            >
              <span style="color: #eead2d">{{ product.farma4.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma4.Stock_Pendent != '0'"
              >
                - {{ product.farma4.Stock_Pendent }}</span
              >
              - {{ product.farma4.Preco }} €
            </p>
            <p v-else-if="product.farma4.Stock > 5">
              <span style="color: green">{{ product.farma4.Stock }}</span
              ><span
                style="color: gray"
                v-if="product.farma4.Stock_Pendent != '0'"
              >
                - {{ product.farma4.Stock_Pendent }}</span
              >
              - {{ product.farma4.Preco }} €
            </p>
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              v-model="product.OCP.Total"
              :min="0"
              :disabled="
                product.OCP.Disponibilidade == 'Indisponível' ||
                product.OCP.Disponibilidade == 'Nao Disponivel' ||
                product.OCP.Disponibilidade == 'Nao Comercializado' ||
                product.OCP.Disponibilidade == '0'
              "
              @change="
                updateOCP(
                  product.OCP.Total,
                  product.name,
                  product.sku,
                  product.ean,
                  product.id_prod,
                  product.OCP.PVFFinal
                )
              "
            />
            <p v-if="product.OCP.Disponibilidade == 'Disponivel'">
              <span style="color: green">{{
                product.OCP.Disponibilidade
              }}</span>
              - {{ product.OCP.PVFFinal }} €
            </p>
            <p v-else>
              <span style="color: red">{{ product.OCP.Disponibilidade }}</span>
              - {{ product.OCP.PVFFinal }} €
            </p>
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              v-model="product.engel.Total"
              :min="0"
              :max="product.engel.Stock"
              :disabled="
                Number(product.engel.Stock) <= 0 ||
                product.engel.Stock == 'Indisponível'
              "
              @change="
                updateEngel(
                  product.engel.Total,
                  product.name,
                  product.sku,
                  product.ean,
                  product.id_prod,
                  product.engel.Preco,
                  product.ean
                )
              "
            />
            <p
              v-if="
                product.engel.Stock <= 0 ||
                product.engel.Stock == 'Indisponível'
              "
            >
              <span style="color: red">{{ product.engel.Stock }} </span>-
              {{ product.engel.Preco }} €
            </p>
            <p v-else-if="product.engel.Stock > 0 && product.engel.Stock <= 5">
              <span style="color: #eead2d">{{ product.engel.Stock }}</span> -
              {{ product.engel.Preco }} €
            </p>
            <p v-else-if="product.engel.Stock > 5">
              <span style="color: green">{{ product.engel.Stock }}</span> -
              {{ product.engel.Preco }} €
            </p>
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              v-model="product.marcaTotal"
              :min="0"
              @change="
                updateMarca(
                  product.marcaTotal,
                  product.name,
                  product.sku,
                  product.ean,
                  product.id_prod,
                  0
                )
              "
            />
            <small v-if="product.id_laboratories == null" style="color: red"
              >Esta Marca não é comprada pela Daniela</small
            >
          </td>
          <td>
            <div
              v-for="(supplier, index) in externalSupplier.filter(
                (sup) => sup.sku == product.sku
              )"
              :key="index"
            >
              <div id="supplierInfo">
                {{ supplier.supplier.qty }} x {{ supplier.supplier.name }} -
                {{ supplier.supplier.price }} €
                <p @click="removeSupplier(supplier)">❌</p>
              </div>
              <br />
            </div>
            <button
              class="btn btn-primary"
              id="openModal"
              v-b-modal.modal-extern
              @click="modalSupplier(product.sku)"
            >
              ➕
            </button>
          </td>
        </tr>
      </table>
      <h1 style="text-align: center">Bloqueados</h1>
      <div style="margin: 10px 0; text-align: center">
        <!-- Botão de Desbloqueio -->
        <button
          class="btn btn-success"
          @click="
            confirmPendentBatch(false, selectedProductsUnlock, 'Desbloqueio')
          "
        >
          Desbloquear Selecionados
        </button>
      </div>
      <b-table
        striped
        hover
        :items="productsMissingLocked"
        :fields="fields1"
        :per-page="perPage1"
        :current-page="currentPage"
        class="table"
        small
        thead-class="text-white"
      >
        <template #cell(sku)="data">
          <input
            type="checkbox"
            v-model="selectedProductsUnlock"
            :value="data.item.sku"
          />
          <br />
          <a id="openArticle" @click="passProductData(data.item, true)">{{
            data.item.sku
          }}</a>
          <br />
          {{ data.item.ean }}
          <p v-if="data.item.date_order != undefined" style="font-size: 90%">
            <span
              v-if="calculateDifferenceBetweenDates(data.item.date_order) == 2"
              style="background: blue; color: white"
            >
              {{ splitDate2(data.item.date_order) }}
            </span>
            <span
              v-else-if="
                calculateDifferenceBetweenDates(data.item.date_order) == 3
              "
              style="background: orange; color: white"
            >
              {{ splitDate2(data.item.date_order) }}
            </span>
            <span
              v-else-if="
                calculateDifferenceBetweenDates(data.item.date_order) == 4
              "
              style="background: red; color: white"
            >
              {{ splitDate2(data.item.date_order) }}
            </span>
            <span
              v-else-if="
                calculateDifferenceBetweenDates(data.item.date_order) > 4
              "
              style="background: black; color: white"
            >
              {{ splitDate2(data.item.date_order) }}
            </span>
            <span v-else style="background: white">
              {{ splitDate2(data.item.date_order) }}
            </span>
          </p>
        </template>
        <template #cell(name)="data">
          <a>{{ data.item.name }}</a>
          <br />
          ({{ data.item.price_eur }} €)
          <br />
          <a id="setPendent" @click="confirmPendent(false, data.item.sku)"
            >Desbloquear Produto</a
          >
        </template>
        <template #cell(reason)="data">
          <p>
            <b>{{ data.item.reason }}</b>
          </p>
          <p>{{ splitDate(data.item.date) }}</p>
        </template>
        <template #cell(farma1)="data">
          <p
            v-if="
              data.item.farma1.Stock <= 0 ||
              data.item.farma1.Stock == 'Indisponível'
            "
          >
            <span style="color: red">{{ data.item.farma1.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma1.Stock_Pendent != '0'"
            >
              - {{ data.item.farma1.Stock_Pendent }}</span
            >
            - {{ data.item.farma1.Preco }} €
          </p>
          <p
            v-else-if="
              data.item.farma1.Stock > 0 && data.item.farma1.Stock <= 5
            "
          >
            <span style="color: #eead2d">{{ data.item.farma1.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma1.Stock_Pendent != '0'"
            >
              - {{ data.item.farma1.Stock_Pendent }}</span
            >
            - {{ data.item.farma1.Preco }} €
          </p>
          <p v-else-if="data.item.farma1.Stock > 5">
            <span style="color: green">{{ data.item.farma1.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma1.Stock_Pendent != '0'"
            >
              - {{ data.item.farma1.Stock_Pendent }}</span
            >
            - {{ data.item.farma1.Preco }} €
          </p>
        </template>
        <template #cell(farma2)="data">
          <p
            v-if="
              data.item.farma2.Stock <= 0 ||
              data.item.farma2.Stock == 'Indisponível'
            "
          >
            <span style="color: red">{{ data.item.farma2.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma2.Stock_Pendent != '0'"
            >
              - {{ data.item.farma2.Stock_Pendent }}</span
            >
            - {{ data.item.farma2.Preco }} €
          </p>
          <p
            v-else-if="
              data.item.farma2.Stock > 0 && data.item.farma2.Stock <= 5
            "
          >
            <span style="color: #eead2d">{{ data.item.farma2.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma2.Stock_Pendent != '0'"
            >
              - {{ data.item.farma2.Stock_Pendent }}</span
            >
            - {{ data.item.farma2.Preco }} €
          </p>
          <p v-else-if="data.item.farma2.Stock > 5">
            <span style="color: green">{{ data.item.farma2.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma2.Stock_Pendent != '0'"
            >
              - {{ data.item.farma2.Stock_Pendent }}</span
            >
            - {{ data.item.farma2.Preco }} €
          </p>
        </template>
        <template #cell(farma3)="data">
          <p
            v-if="
              data.item.farma3.Stock <= 0 ||
              data.item.farma3.Stock == 'Indisponível'
            "
          >
            <span style="color: red">{{ data.item.farma3.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma3.Stock_Pendent != '0'"
            >
              - {{ data.item.farma3.Stock_Pendent }}</span
            >
            - {{ data.item.farma3.Preco }} €
          </p>
          <p
            v-else-if="
              data.item.farma3.Stock > 0 && data.item.farma3.Stock <= 5
            "
          >
            <span style="color: #eead2d">{{ data.item.farma3.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma3.Stock_Pendent != '0'"
            >
              - {{ data.item.farma3.Stock_Pendent }}</span
            >
            - {{ data.item.farma3.Preco }} €
          </p>
          <p v-else-if="data.item.farma3.Stock > 5">
            <span style="color: green">{{ data.item.farma3.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma3.Stock_Pendent != '0'"
            >
              - {{ data.item.farma3.Stock_Pendent }}</span
            >
            - {{ data.item.farma3.Preco }} €
          </p>
        </template>
        <template #cell(farma4)="data">
          <p
            v-if="
              data.item.farma4.Stock <= 0 ||
              data.item.farma4.Stock == 'Indisponível'
            "
          >
            <span style="color: red">{{ data.item.farma4.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma4.Stock_Pendent != '0'"
            >
              - {{ data.item.farma4.Stock_Pendent }}</span
            >
            - {{ data.item.farma4.Preco }} €
          </p>
          <p
            v-else-if="
              data.item.farma4.Stock > 0 && data.item.farma4.Stock <= 5
            "
          >
            <span style="color: #eead2d">{{ data.item.farma4.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma4.Stock_Pendent != '0'"
            >
              - {{ data.item.farma4.Stock_Pendent }}</span
            >
            - {{ data.item.farma4.Preco }} €
          </p>
          <p v-else-if="data.item.farma4.Stock > 5">
            <span style="color: green">{{ data.item.farma4.Stock }}</span
            ><span
              style="color: gray"
              v-if="data.item.farma4.Stock_Pendent != '0'"
            >
              - {{ data.item.farma4.Stock_Pendent }}</span
            >
            - {{ data.item.farma4.Preco }} €
          </p>
        </template>
        <template #cell(OCP)="data">
          <p v-if="data.item.OCP.Disponibilidade == 'Disponivel'">
            <span style="color: green">{{
              data.item.OCP.Disponibilidade
            }}</span>
            - {{ data.item.OCP.PVFFinal }} €
          </p>
          <p v-else>
            <span style="color: red">{{ data.item.OCP.Disponibilidade }}</span>
            - {{ data.item.OCP.PVFFinal }} €
          </p>
        </template>
        <template #cell(engel)="data">
          <p
            v-if="
              data.item.engel.Stock <= 0 ||
              data.item.engel.Stock == 'Indisponível'
            "
          >
            <span style="color: red">{{ data.item.engel.Stock }}</span> -
            {{ data.item.engel.Preco }} €
          </p>
          <p
            v-else-if="data.item.engel.Stock > 0 && data.item.engel.Stock <= 5"
          >
            <span style="color: #eead2d">{{ data.item.engel.Stock }}</span> -
            {{ data.item.engel.Preco }} €
          </p>
          <p v-else-if="data.item.engel.Stock > 5">
            <span style="color: green">{{ data.item.engel.Stock }}</span> -
            {{ data.item.engel.Preco }} €
          </p>
        </template>
      </b-table>
    </div>
    <div v-else-if="page == 'histórico'">
      <h1 style="text-align: center">Compras feitas</h1>
      <b-table
        striped
        hover
        :items="productsRequested"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        class="table"
        small
        thead-class="text-white"
      >
        <template #cell(sku)="data">
          <a id="openArticle" @click="passProductData(data.item, true)">{{
            data.item.sku
          }}</a>
        </template>
        <template #cell(id_invoice)="data">
          <a v-if="data.item.id_invoice == 'Em espera'">{{
            data.item.id_invoice
          }}</a>
          <a v-else>
            <a>
              <b>{{ data.item.id_invoice }}</b></a
            >
            <br />
            <a id="openPage" @click="pickPurchase(data.item.id_invoice)">➕</a>
            <a> || </a>
            <a id="openPage" @click="editPurchase(data.item.id_invoice)">✏️</a>
          </a>
        </template>
        <template #cell(date_request)="data">
          <span
            v-if="calculateDifferenceBetweenDates(data.item.date_request) < 3"
          >
            {{ splitDate(data.item.date_request) }}
          </span>
          <span
            v-if="
              calculateDifferenceBetweenDates(data.item.date_request) >= 3 &&
              calculateDifferenceBetweenDates(data.item.date_request) < 5
            "
            style="background: orange"
          >
            {{ splitDate(data.item.date_request) }}</span
          >
          <span
            v-if="calculateDifferenceBetweenDates(data.item.date_request) >= 5"
            style="background: red"
          >
            {{ splitDate(data.item.date_request) }}</span
          >
        </template>
      </b-table>
    </div>
    <!-- MODAIS -->
    <b-modal
      id="modal-extern"
      title="Adicionar Fornecedor!"
      ref="modal_externSupplier"
      hide-footer
    >
      <form v-on:submit.prevent="addExternalSupplier()">
        <div class="form-group">
          <label for="sku">SKU</label>
          <input
            type="text"
            class="form-control"
            id="sku"
            :placeholder="this.sku"
          />
        </div>
        <div class="form-group">
          <label for="provider">Fornecedor</label>
          <v-select
            :options="suppliers"
            v-model="supplier"
            :reduce="(supp) => supp.name"
            label="name"
          >
            <template slot="option" slot-scope="option">
              {{ option.name }}</template
            >
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}</template
            >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!supplier"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
          <!-- <select class="form-select" aria-label="Default select example" v-model="supplier" required>
            <option v-for="supplier in suppliers" :key="supplier.id">
              {{ supplier.name }} ({{ supplier.other_name }})
            </option>
          </select> -->
        </div>
        <div class="form-group">
          <label for="qty">Quantidade</label>
          <input
            type="number"
            class="form-control"
            id="qty"
            v-model="qtyExtern"
            :min="0"
          />
        </div>
        <div class="form-group">
          <label for="price">Preço</label>
          <input
            type="number"
            class="form-control"
            min="0"
            step="any"
            id="price"
            v-model="priceExtern"
          />
        </div>
        <div class="form-group">
          <label for="price">Desc. Individual</label>
          <input
            type="number"
            class="form-control"
            id="price"
            step="any"
            v-model="discount"
          />
        </div>
        <div>
          Total: {{ this.qtyExtern * this.priceExtern - this.discount }}
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </b-modal>
    <b-modal
      id="add-supplier"
      title="Adicionar Novo Fornecedor!"
      ref="modal_add-supplier"
      hide-footer
    >
      <form v-on:submit.prevent="addSupplier()">
        <div class="form-group">
          <label for="sku">Nome</label>
          <input
            type="text"
            class="form-control"
            id="supp_name"
            v-model="supp_name"
            required
          />
        </div>
        <div class="form-group">
          <label for="qty">Outro Nome</label>
          <input
            type="text"
            class="form-control"
            id="supp_other_name"
            v-model="supp_other_name"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
    <b-modal
      id="add-prod"
      title="Adicionar produto extra!"
      ref="modal_add-prod"
      hide-footer
    >
      <form v-on:submit.prevent="addProd()">
        <div class="form-group">
          <label for="sku">SKU</label>
          <input
            type="text"
            class="form-control"
            id="new_sku"
            v-model="new_sku"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
    <b-modal
      id="create-prod"
      title="Criar produto extra!"
      ref="modal_create-prod"
      hide-footer
    >
      <form v-on:submit.prevent="createProd()">
        <div class="form-group">
          <label for="sku">Nome</label>
          <input
            type="text"
            class="form-control"
            id="new_name"
            v-model="new_name"
            required
          />
          <label for="sku">SKU</label>
          <input
            type="text"
            class="form-control"
            id="new_sku"
            v-model="new_sku"
            required
          />
          <label for="sku">EAN</label>
          <input
            type="text"
            class="form-control"
            id="new_ean"
            v-model="new_ean"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
    <b-modal
      ref="modal_product"
      id="modal-3"
      :title="'Produto ' + this.skuProduct"
      size="lg"
      hide-footer
    >
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
              <p>
                <span id="subtitle">Notas Arm.:</span> {{ warehouseProduct }}
              </p>
              <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <!-- <button class="btn btn-info" @click="$router.push('/product/' + idProduct)">Editar Produto</button> -->
        <button class="btn btn-info" @click="openProdPage()">
          Editar Produto
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="modal_extern"
      id="modal-7"
      :title="'Produto ' + this.skuProduct"
      size="xl"
      hide-footer
    >
      <div v-for="(supplier, j) of suppliersProducts" :key="supplier.supplier">
        <h5>
          <b>{{ supplier.supplier }}</b>
        </h5>
        <table style="font-size: 80%">
          <tr>
            <th>CNP/SKU</th>
            <th style="width: 30%">Nome</th>
            <th>Quantidade</th>
            <th>Preço</th>
            <th>Desc. Individual</th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="(product, index) of supplier.products" :key="product.sku">
            <td>
              <a id="openArticle" @click="passProductData(product, false)">{{
                product.sku
              }}</a>
            </td>
            <td>
              {{ product.name }}
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="supplier.products[index].qty"
                style="font-size: 100%"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="supplier.products[index].price"
                style="font-size: 100%"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="supplier.products[index].discount"
                style="font-size: 100%"
              />
            </td>
            <td>
              {{
                (
                  Number(product.qty) * Number(product.price) -
                  Number(product.discount)
                ).toFixed(2)
              }}€
            </td>
            <td>
              <button
                class="btn btn-danger"
                @click="removeProduct(supplier.supplier, index)"
              >
                -
              </button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Total:</b></td>
            <td>
              {{
                supplier.products
                  .reduce((sum, product) => {
                    return (
                      sum +
                      Number(product.price) * Number(product.qty) -
                      Number(product.discount)
                    );
                  }, 0)
                  .toFixed(2)
              }}€
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Desconto Geral:</b></td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="suppliersProducts[j].generalDiscount"
                style="font-size: 100%"
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Extra:</b></td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="suppliersProducts[j].valueExtra"
                style="font-size: 100%"
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Total a Pagar:</b></td>
            <td>
              {{
                supplier.products
                  .reduce((sum, product) => {
                    return (
                      sum +
                      Number(product.price) * Number(product.qty) -
                      Number(product.discount)
                    );
                  }, -Number(supplier.generalDiscount) + Number(supplier.valueExtra))
                  .toFixed(2)
              }}€
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Método de Pagamento</b></td>
            <td>
              <select
                class="form-select form-select"
                id="exampleFormControlSelect1"
                v-model="suppliersProducts[j].paymentMethod"
                style="font-size: 100%"
                required
              >
                <option value="" disabled selected>
                  Selecione um método de pagamento
                </option>
                <option value="PayPal">PayPal</option>
                <option value="Referência MB">Referência MB</option>
                <option value="Cartão Crédito">Cartão Crédito</option>
                <option value="Transferência Bancária">
                  Transferência Bancária
                </option>
                <option value="Revolut">Revolut</option>
              </select>
            </td>
            <td></td>
          </tr>
        </table>
        <hr />
      </div>
      <div style="text-align: center">
        <button class="btn btn-success" @click="externConfirm">
          Confirmar
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import exportFromJSON from 'export-from-json';

export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      productsMissing: [],
      productsMissingLocked: [],
      /* farma1Ex: [qtyOrder, qtyConfirmed, sku, price], */
      farma1: [],
      farma2: [],
      farma3: [],
      farma4: [],
      marcas: [],
      test: '',
      loading: false,
      ocp: [],
      engel: [],
      suppliers: [],
      supplier: '',
      sku: '',
      externalSupplier: [],
      qtyExtern: '',
      suppliersProducts: [],
      priceExtern: '',
      ocp_ids: [],
      supp_name: '',
      supp_other_name: '',
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      discount: 0,
      paymentMethod: '',
      page: 'histórico',
      productsRequested: [],
      selectedProducts: [], // Armazena os SKUs selecionados
      selectedProductsUnlock: [], // Armazena os SKUs selecionados
      fields: [
        {
          key: 'sku',
          label: 'SKU',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'ean',
          label: 'EAN',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'id_invoice',
          label: 'Fatura',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'supplier',
          label: 'Fornecedor',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'qty_request',
          label: 'Qnt. Pedida',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'qty_confirmed',
          label: 'Qnt. Confirmada',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'date_request',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
      ],
      fields1: [
        {
          key: 'sku',
          label: 'SKU',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'reason',
          label: 'Razão',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'missing',
          label: 'Em falta',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'farma1',
          label: 'Alvim',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'farma2',
          label: 'Gomes',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'farma3',
          label: 'Perelhal',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'farma4',
          label: 'Martins',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'OCP',
          label: 'OCP',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'engel',
          label: 'Nova Engel',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
      ],
      perPage: 0,
      perPage1: 0,
      currentPage: 1,
      role: '',
      date: '',
      new_sku: '',
      new_name: '',
      new_ean: '',
    };
  },
  methods: {
    /* async getPurchase() {
      console.log("POR FAVOOOOR");
      await this.$store.dispatch("getPurchasesTransactions");
      console.log(this.getPurchaseTransactions);
      let test = this.getPurchase.filter((element) => (element.sku = 6942714));
      console.log("TESTE", test);
    }, */
    calculateDifferenceBetweenDates(date) {
      let dateNow = new Date();
      let year = dateNow.getFullYear();
      let month = ('0' + (dateNow.getMonth() + 1)).slice(-2);
      let day = ('0' + dateNow.getDate()).slice(-2);
      let finalDateNow = `${year}-${month}-${day}`;
      let orderDate = this.splitDate(date);
      /* USAR BIBLIOTECA diff PARA COMPARAR AS DATAS */
      let diff = moment(finalDateNow).diff(orderDate, 'days');
      return Number(diff);
    },
    async openPageInNewTab() {
      window.open(`https://${window.location.host}/reportSales`, '_blank');
    },
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        '_blank'
      );
      this.$refs['modal_product'].hide();
    },
    async movement(new_page) {
      if (new_page == 'compras') {
        this.loading = true;
        await this.missingProducts();
        this.page = new_page;
        this.loading = false;
      } else if (new_page == 'histórico') {
        this.loading = true;
        await this.requestProducts();
        this.page = new_page;
        this.loading = false;
      }
    },
    async addProd() {
      try {
        await this.$store.dispatch('addProductPurchase', { sku: this.new_sku });
        const obj = this.getProductExtraInfo;
        this.productsMissing.push(obj);
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Produto não encontrado',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.new_sku = '';
      this.$refs['modal_add-prod'].hide();
    },
    async createProd() {
      try {
        await this.$store.dispatch('createProductPurchase', {
          name: this.new_name,
          sku: this.new_sku,
          ean: this.new_ean,
        });
        const obj = this.getProductExtraInfo;
        this.productsMissing.push(obj);
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Produto não encontrado',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.new_sku = '';
      this.new_ean = '';
      this.new_name = '';
      this.$refs['modal_create-prod'].hide();
    },
    async addSupplier() {
      console.log('ENTREIIII');
      try {
        await this.$store.dispatch('addSuppliers', {
          name: this.supp_name,
          other_name: this.supp_other_name,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Fornecedor adicionado',
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getAllSuppliers();
        this.$refs['modal_add-supplier'].hide();
      } catch (err) {
        console.log(err);
      }
    },
    async missingProducts() {
      this.loading = true;
      try {
        await this.$store.dispatch('getProductsMissing');
        this.productsMissing = this.getMissingProducts;
        this.productsMissingLocked = this.getMissingProductsLocked;
        this.date = this.getMissingProductsDate;
        this.perPage1 = this.productsMissingLocked.length;
        console.log(this.getMissingProducts);
        if (this.productsMissing.length == 0) {
          this.message = 'Não há produtos em falta';
        }
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async requestProducts() {
      this.loading = true;
      try {
        await this.$store.dispatch('getProductsRequests');
        this.productsRequested = this.getProductTransactionsRequest;
        if (this.productsRequested.length == 0) {
          this.message = 'Não há transações pendentes';
        } else {
          // for (let index = 0; index < this.productsRequested.length; index++) {
          //   const element = this.productsRequested[index];
          //   const filterProduct1 = this.products.filter((product) => product.sku.includes(element.sku));
          //   this.productsRequested[index].name = filterProduct1[0].name;
          //   this.productsRequested[index].ean = filterProduct1[0].ean[0];
          // }
          this.perPage = this.productsRequested.length;
        }
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async updateFarma1(event, name, sku, ean, id_prod, preco) {
      let find = this.farma1.findIndex((product) => product.sku == sku);
      if (find == -1) {
        this.farma1.push({
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        });
      } else {
        this.farma1[find] = {
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        };
      }
      let index = this.productsMissing.findIndex(
        (product) => product.sku == sku
      );
      if (event == 0 || event == '') {
        this.productsMissing[index].farma1.Total = 0;
      } else {
        this.productsMissing[index].farma1.Total = Number(event);
      }
      console.log(this.productsMissing[index]);
    },
    async updateFarma2(event, name, sku, ean, id_prod, preco) {
      let find = this.farma2.findIndex((product) => product.sku == sku);
      if (find == -1) {
        this.farma2.push({
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        });
      } else {
        this.farma2[find] = {
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        };
      }
      let index = this.productsMissing.findIndex(
        (product) => product.sku == sku
      );
      if (event == 0 || event == '') {
        this.productsMissing[index].farma2.Total = 0;
      } else {
        this.productsMissing[index].farma2.Total = event;
      }
      console.log(this.farma2);
    },
    async updateFarma3(event, name, sku, ean, id_prod, preco) {
      let find = this.farma3.findIndex((product) => product.sku == sku);
      if (find == -1) {
        this.farma3.push({
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        });
      } else {
        this.farma3[find] = {
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        };
      }
      let index = this.productsMissing.findIndex(
        (product) => product.sku == sku
      );
      if (event == 0 || event == '') {
        this.productsMissing[index].farma3.Total = 0;
      } else {
        this.productsMissing[index].farma3.Total = event;
      }
      console.log(this.farma3);
    },
    async updateFarma4(event, name, sku, ean, id_prod, preco) {
      let find = this.farma4.findIndex((product) => product.sku == sku);
      if (find == -1) {
        this.farma4.push({
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        });
      } else {
        this.farma4[find] = {
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        };
      }
      let index = this.productsMissing.findIndex(
        (product) => product.sku == sku
      );
      if (event == 0 || event == '') {
        this.productsMissing[index].farma4.Total = 0;
      } else {
        this.productsMissing[index].farma4.Total = event;
      }
      console.log(this.farma4);
    },
    async updateOCP(event, name, sku, ean, id_prod, preco) {
      let find = this.ocp.findIndex((product) => product.sku == sku);
      if (find == -1) {
        this.ocp.push({
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        });
      } else {
        this.OCP[find] = {
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        };
      }
      let index = this.productsMissing.findIndex(
        (product) => product.sku == sku
      );
      if (event == 0 || event == '') {
        this.productsMissing[index].OCP.Total = 0;
      } else {
        this.productsMissing[index].OCP.Total = event;
      }
      console.log('OCPPPPPP', this.ocp);
    },
    async updateEngel(event, name, sku, ean, id_prod, preco) {
      let find = this.engel.findIndex((product) => product.sku == sku);
      if (find == -1) {
        this.engel.push({
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        });
      } else {
        this.engel[find] = {
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        };
      }
      let index = this.productsMissing.findIndex(
        (product) => product.sku == sku
      );
      if (event == 0 || event == '') {
        this.productsMissing[index].engel.Total = 0;
      } else {
        this.productsMissing[index].engel.Total = event;
      }
      console.log('engel', this.engel);
    },
    async updateMarca(event, name, sku, ean, id_prod, preco) {
      let find = this.marcas.findIndex((product) => product.sku == sku);
      if (find == -1) {
        this.marcas.push({
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        });
      } else {
        this.marcas[find] = {
          id_prod: id_prod,
          name: name,
          sku: sku,
          ean: ean,
          qty_order: event,
          qty_confirmed: 0,
          price: preco,
        };
      }
      console.log('marcas', this.marcas);
    },
    modalSupplier(sku) {
      this.sku = sku;
    },
    editPurchase(id) {
      window.open(
        `https://${window.location.host}/receptionsProducts/${id}/updateData`,
        '_blank'
      );
    },
    pickPurchase(id) {
      window.open(
        `https://${window.location.host}/receptionProducts/${id}/update`,
        '_blank'
      );
    },
    async addExternalSupplier() {
      /* let index = this.productsMissing.findIndex(
        (product) => product.sku == this.sku
      );
      console.log(this.sku);
      console.log("INDICE: ", index);
      this.productsMissing[index].externTotal = this.qtyExtern; */
      /* let i = this.externalSupplier.findIndex((product) => product.sku); */
      let index = this.productsMissing.findIndex(
        (product) => product.sku == this.sku
      );
      this.productsMissing[index].externTotal =
        this.productsMissing[index].externTotal + Number(this.qtyExtern);
      this.externalSupplier.push({
        id_prod: this.productsMissing[index].id_prod,
        sku: this.sku,
        supplier: {
          name: this.supplier,
          price: this.priceExtern,
          qty: this.qtyExtern,
          discount: this.discount,
        },
      });
      this.sku = '';
      this.supplier = '';
      this.priceExtern = '';
      this.qtyExtern = '';
      this.$refs['modal_externSupplier'].hide();
    },

    async createSuppliersArray(finish) {
      let allProducts = [];
      if (!finish) {
        this.suppliersProducts = [];
      }
      for (let i = 0; i < this.externalSupplier.length; i++) {
        let index = this.suppliersProducts.findIndex(
          (supplier) =>
            supplier.supplier == this.externalSupplier[i].supplier.name
        );
        console.log('INDEX SUPPLIERS: ', index);
        if (index == -1) {
          let filter = this.externalSupplier.filter(
            (sup) => sup.supplier.name == this.externalSupplier[i].supplier.name
          );
          console.log('Filtro:', filter);
          for (const key of filter) {
            // const filterProduct1 = this.products.filter((prod) => prod.sku.includes(key.sku));
            const filterProduct1 = this.productsMissing.filter((prod) =>
              prod.sku.includes(key.sku)
            );
            console.log('Filtro Produtos:', filterProduct1);

            allProducts.push({
              id_prod: filterProduct1[0].id_prod,
              sku: key.sku,
              name: filterProduct1[0].name,
              ean: filterProduct1[0].ean,
              qty: key.supplier.qty,
              price: key.supplier.price,
              discount: key.supplier.discount,
            });
            console.log('LOL: ', allProducts);
            console.log('CHAVE: ', key);
          }
          this.suppliersProducts.push({
            supplier: this.externalSupplier[i].supplier.name,
            products: allProducts,
            generalDiscount: 0,
            valueExtra: 0,
            paymentMethod: null,
          });
          allProducts = [];
        }
      }
      console.log('RETORNO:', this.suppliersProducts);
      return this.suppliersProducts;
    },
    async addTransition(supplier) {
      console.log('ADICIONAR TRANSIÇÂO ', supplier);
      let ids = [];
      let ids_engel = [];
      let invoiceData = [];
      this.loading = true;
      let supplierName;
      let arraySupplier;
      let farma_email;
      switch (supplier) {
        case 'Farmácia Alvim':
          console.log('FARMAAAAA', this.farma1);
          arraySupplier = this.farma1;
          supplier = 'FARMACIA ALVIM (ALVIM)';
          supplierName = 'farma1';
          farma_email = 'farmaciaalvim@gssaude.pt';
          break;
        case 'Farmácia Gomes':
          arraySupplier = this.farma2;
          supplier = 'FARMACIA GOMES (GOMES)';
          supplierName = 'farma2';
          farma_email = 'farmaciagomes@gssaude.pt';
          break;
        case 'Farmácia Perelhal':
          arraySupplier = this.farma3;
          supplier = 'FARMACIA PERELHAL (PERELHAL)';
          supplierName = 'farma3';
          farma_email = 'farmaciagomesperelhal@gssaude.pt';
          break;
        case 'Farmácia Martins':
          arraySupplier = this.farma4;
          supplier = 'FARMACIA MARTINS (MARTINS)';
          supplierName = 'farma4';
          farma_email = 'farmaciamartins@gssaude.pt';
          break;
        case 'OCP Portugal':
          arraySupplier = this.ocp;
          supplier = 'OCP PORTUGAL (-)';
          supplierName = 'ocp';
          farma_email = 'farmaciaalvim@gssaude.pt';
          break;
        case 'NOVA ENGEL (ENGEL)':
          arraySupplier = this.engel;
          supplier = 'NOVA ENGEL (ENGEL)';
          supplierName = 'engel';
          break;
        case 'extern':
          arraySupplier = await this.createSuppliersArray(true);
          supplierName = 'extern';
          break;
        case 'marcas':
          arraySupplier = this.marcas;
          supplierName = 'marcas';
          break;
      }
      for (let index = 0; index < arraySupplier.length; index++) {
        let email = false;
        if (index === 0) {
          email = true;
        }
        try {
          if (
            arraySupplier[index].qty_order != 0 &&
            supplierName != 'extern' &&
            supplierName != 'engel' &&
            supplierName != 'marcas'
          ) {
            const id_supp = this.suppliers.filter(
              (supp) => supp.name == supplier
            );
            console.log('FORNECEDORES EXTERNOS TRASAÇÃO: ', arraySupplier);
            await this.$store.dispatch('addTransactionPurchase', {
              id_prod: arraySupplier[index].id_prod,
              id_supp: id_supp[0].id,
              name: arraySupplier[index].name,
              sku: arraySupplier[index].sku,
              ean: arraySupplier[index].ean,
              supplier: supplier,
              qty_order: arraySupplier[index].qty_order,
              price: arraySupplier[index].price,
              qty_confirmed: arraySupplier[index].qty_confirmed,
              email: email,
              farma_email: farma_email,
              discount: 0,
            });
            let i = this.productsMissing.findIndex(
              (product) => product.sku == arraySupplier[index].sku
            );
            this.productsMissing[i].missing =
              Number(this.productsMissing[i].missing) -
              Number(arraySupplier[index].qty_order);
            console.log('Teste', this.productsMissing[i].farma1.Total);
            if (supplierName == 'farma1') {
              console.log('FARMA1');
              console.log('Teste', this.productsMissing[i].farma1.Total);
              this.productsMissing[i].farma1.Total = 0;
              console.log('Teste', this.productsMissing[i].farma1.Total);
              this.farma1 = [];
            } else if (supplierName == 'farma2') {
              console.log('FARMA2');
              console.log('Teste', this.productsMissing[i].farma2.Total);
              this.productsMissing[i].farma2.Total = 0;
              this.farma2 = [];
            } else if (supplierName == 'farma3') {
              console.log('FARMA3');
              console.log('Teste', this.productsMissing[i].farma3.Total);
              this.productsMissing[i].farma3.Total = 0;
              this.farma3 = [];
            } else if (supplierName == 'farma4') {
              console.log('FARMA4');
              console.log('Teste', this.productsMissing[i].farma4.Total);
              this.productsMissing[i].farma4.Total = 0;
              this.farma4 = [];
            } else if (supplierName == 'ocp') {
              console.log('OCP');
              console.log('Teste', this.productsMissing[i].OCP.Total);
              this.productsMissing[i].OCP.Total = 0;
              this.ocp = [];
            }
          } else if (supplierName == 'engel') {
            console.log('ENGELLLL!');
            console.log(arraySupplier[index]);
            const id_supp = this.suppliers.filter(
              (supp) => supp.name == supplier
            );
            await this.$store.dispatch('addTransactionPurchase', {
              name: arraySupplier[index].name,
              sku: arraySupplier[index].sku,
              ean: arraySupplier[index].ean,
              supplier: supplier,
              qty_order: arraySupplier[index].qty_order,
              price: arraySupplier[index].price,
              qty_confirmed: arraySupplier[index].qty_order,
              discount: 0,
              id_prod: arraySupplier[index].id_prod,
              id_supp: id_supp[0].id,
            });
            let i = this.productsMissing.findIndex(
              (product) => product.sku == arraySupplier[index].sku
            );
            this.productsMissing[i].missing =
              Number(this.productsMissing[i].missing) -
              Number(arraySupplier[index].qty_order);
            this.productsMissing[index].engel.Total = 0;
            ids_engel.push(this.getTransactionsId);
            console.log('IDS ', ids_engel);
            this.engel = [];
          } else if (supplierName == 'extern') {
            console.log('EXTERNOOOO!');
            console.log(arraySupplier[index]);
            for (let j = 0; j < arraySupplier[index].products.length; j++) {
              const id_supp = this.suppliers.filter(
                (supp) => supp.name == arraySupplier[index].supplier
              );
              console.log('ENTREI', arraySupplier[index].products[j]);
              await this.$store.dispatch('addTransactionPurchase', {
                name: arraySupplier[index].products[j].name,
                sku: arraySupplier[index].products[j].sku,
                ean: arraySupplier[index].products[j].ean,
                supplier: arraySupplier[index].supplier,
                qty_order: arraySupplier[index].products[j].qty,
                price: arraySupplier[index].products[j].price,
                qty_confirmed: arraySupplier[index].products[j].qty,
                discount: arraySupplier[index].products[j].discount,
                id_prod: arraySupplier[index].products[j].id_prod,
                id_supp: id_supp[0].id,
              });
              let i = this.productsMissing.findIndex(
                (product) => product.sku == arraySupplier[index].products[j].sku
              );
              this.productsMissing[i].missing =
                Number(this.productsMissing[i].missing) -
                Number(arraySupplier[index].products[j].qty);
              this.productsMissing[i].externTotal = 0;
              ids.push(this.getTransactionsId);
              console.log(ids);
            }
            console.log('TESTEEEE: ', arraySupplier[index]);
            invoiceData.push({
              supplier: arraySupplier[index].supplier,
              products: ids,
              generalDiscount: arraySupplier[index].generalDiscount,
              valueExtra: arraySupplier[index].valueExtra,
              paymentMethod: arraySupplier[index].paymentMethod,
            });
            ids = [];
            console.log(invoiceData);
            this.externalSupplier = [];
            this.suppliersProducts = [];
          } else if (supplierName == 'marcas') {
            console.log('MARCAAAAA!');
            console.log(arraySupplier[index]);
            const id_supp = this.suppliers.filter(
              (supp) => supp.name == 'marcas (marcas)'
            );
            console.log('ENTREI', this.suppliers);
            console.log('ENTREI', supplier, id_supp);
            await this.$store.dispatch('addTransactionPurchase', {
              name: arraySupplier[index].name,
              sku: arraySupplier[index].sku,
              ean: arraySupplier[index].ean,
              supplier: supplier,
              qty_order: arraySupplier[index].qty_order,
              price: arraySupplier[index].price,
              qty_confirmed: arraySupplier[index].qty_confirmed,
              discount: 0,
              id_prod: arraySupplier[index].id_prod,
              id_supp: id_supp[0].id,
            });
            let i = this.productsMissing.findIndex(
              (product) => product.sku == arraySupplier[index].sku
            );
            this.productsMissing[i].missing =
              Number(this.productsMissing[i].missing) -
              Number(arraySupplier[index].qty_order);
            this.productsMissing[i].marcaTotal = 0;
            console.log(ids);
            this.marcas = [];
          }
        } catch (err) {
          console.log(err);
          alert(err);
        } finally {
          this.loading = false;
        }
      }
      if (ids_engel.length != 0) {
        try {
          const id_supp = this.suppliers.filter(
            (supp) => supp.name == 'NOVA ENGEL (ENGEL)'
          );
          await this.$store.dispatch('createInvoiceTransExtern', {
            id: ids_engel,
            supplier: id_supp[0].id,
            generalDiscount: 0,
            valueExtra: 0,
            paymentMethod: 'Cartão Crédito',
            addToAccounting: true,
          });
        } catch (err) {
          alert(err);
        }
        this.ids_engel = [];
      }
      for (const data of invoiceData) {
        console.log('CRIAÇÂO DE ENCOMENDA', data);
        const id_supp = this.suppliers.filter(
          (supp) => supp.name == data.supplier
        );
        try {
          await this.$store.dispatch('createInvoiceTransExtern', {
            id: data.products,
            supplier: id_supp[0].id,
            generalDiscount: data.generalDiscount,
            valueExtra: data.valueExtra,
            paymentMethod: data.paymentMethod,
            addToAccounting: true,
          });
        } catch (err) {
          alert(err);
        }
      }
    },
    async removeSupplier(supplier) {
      this.externalSupplier.splice(
        this.externalSupplier.findIndex(
          (sup) =>
            sup.sku == supplier.sku &&
            sup.supplier.name == supplier.supplier.name &&
            sup.supplier.qty == supplier.supplier.qty &&
            sup.supplier.price == supplier.supplier.price
        ),
        1
      );
      this.productsMissing[
        this.productsMissing.findIndex((prod) => prod.sku == supplier.sku)
      ].externTotal =
        this.productsMissing[
          this.productsMissing.findIndex((prod) => prod.sku == supplier.sku)
        ].externTotal - supplier.supplier.qty;
    },
    async seeValue(sku) {
      console.log(sku.target.value);
    },
    async confirmModal(supplier) {
      let supplierName;
      let arraySupplier;
      let totalEncomendas = 0;
      console.log('SUPPLIER: ', supplier);
      switch (supplier) {
        case 'Farmácia Alvim':
          console.log('FARMAAAAA', this.farma1);
          arraySupplier = this.farma1;
          supplierName = 'farma1';
          break;
        case 'Farmácia Gomes':
          arraySupplier = this.farma2;
          supplierName = 'farma2';
          break;
        case 'Farmácia Perelhal':
          arraySupplier = this.farma3;
          supplierName = 'farma3';
          break;
        case 'Farmácia Martins':
          arraySupplier = this.farma4;
          supplierName = 'farma4';
          break;
        case 'OCP':
          arraySupplier = this.ocp;
          supplier = 'OCP Portugal';
          supplierName = 'ocp';
          break;
        case 'Nova Engel':
          arraySupplier = this.engel;
          supplier = 'NOVA ENGEL (ENGEL)';
          supplierName = 'engel';
          break;
        case 'marcas':
          arraySupplier = this.marcas;
          supplier = 'marcas';
          supplierName = 'marcas';
          break;
        case 'extern':
          console.log('EXTERNO');
          arraySupplier = await this.createSuppliersArray(false);
          supplierName = 'extern';
          break;
      }
      for (let index = 0; index < arraySupplier.length; index++) {
        if (arraySupplier[index].qty_order != 0 && supplierName != 'extern') {
          if (supplierName == 'farma1') {
            totalEncomendas++;
          } else if (supplierName == 'farma2') {
            totalEncomendas++;
          } else if (supplierName == 'farma3') {
            totalEncomendas++;
          } else if (supplierName == 'farma4') {
            totalEncomendas++;
          } else if (supplierName == 'ocp') {
            totalEncomendas++;
          } else if (supplierName == 'engel') {
            totalEncomendas++;
          } else if (supplierName == 'marcas') {
            totalEncomendas++;
          }
        } else if (supplierName == 'extern') {
          if (this.externalSupplier.length > 0) {
            totalEncomendas++;
          }
        }
      }
      if (totalEncomendas > 0) {
        if (supplierName != 'extern') {
          this.$swal({
            title: 'Quer fazer as compras ao fornecedor ' + supplier + ' ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, comprar!',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.addTransition(supplier);
              this.$swal('Completado', 'Compra efetuada!', 'success');
            }
          });
        } else {
          console.log('EXTERNO');
          this.$refs['modal_extern'].show();
        }
      } else {
        this.$swal({
          title: 'Não existem encomendas ao fornecedor ' + supplier + ' !',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Vou verificar!',
        });
      }
    },
    async purchaseTransactions() {
      this.loading = true;
      try {
        await this.$store.dispatch('getPurchasesTransactions');
      } catch (err) {
        alert(err);
      }
    },
    async getAllSuppliers() {
      try {
        await this.$store.dispatch('getAllSuppliers');
        console.log(this.getSuppliers);
        let obj;
        for (let index = 0; index < this.getSuppliers.length; index++) {
          const element = this.getSuppliers[index];
          obj = {
            id: element.id,
            name: `${element.name} (${element.other_name})`,
          };
          this.suppliers.push(obj);
        }
      } catch (error) {
        alert(error);
      }
    },
    async passProductData(data, info) {
      if (info) {
        window.open(
          `https://${window.location.host}/product/${data.id_prod}`,
          '_blank'
        );
      } else {
        const filterProduct = this.productsMissing.filter((product) =>
          product.sku.includes(data.sku)
        );
        window.open(
          `https://${window.location.host}/product/${filterProduct[0].id_prod}`,
          '_blank'
        );
      }
      // const filterProduct = this.products.filter((product) => product.sku.includes(data.sku));
    },
    async externConfirm() {
      let valid = true;
      for (let index = 0; index < this.suppliersProducts.length; index++) {
        if (
          !this.suppliersProducts[index].paymentMethod ||
          this.suppliersProducts[index].paymentMethod === ''
        ) {
          this.$swal({
            toast: true,
            icon: 'error',
            title:
              'Por favor, selecione um método de pagamento para todos os fornecedores',
            showConfirmButton: false,
            timer: 1500,
          });
          valid = false;
          break;
        }
      }

      // Only show the confirmation dialog if the validation passed
      if (valid) {
        this.$swal({
          title: 'Quer fazer as compras aos fornecedores externos?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, comprar!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.addTransition('extern');
            this.$swal('Completado', 'Compra efetuada!', 'success');
            this.$refs['modal_extern'].hide();
          }
        });
      }
    },
    async removeProduct(supplier, i) {
      let index = this.suppliersProducts.findIndex(
        (supp) => supp.supplier == supplier
      );
      console.log(index);
      this.suppliersProducts[index].products[i];
    },
    splitDate(date) {
      let split = date.split('T');
      let split2 = split[1].split('.');
      return split[0] + ' ' + split2[0];
    },
    splitDate2(date) {
      let split = date.split('T');
      return split[0];
    },
    //Lock
    async confirmPendent(bool, sku, motivo = 'Bloqueio') {
      console.log(bool, sku, motivo);
      if (bool) {
        this.$swal({
          title: 'Quer colocar o produto ' + sku + ' no estado Pendente?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.lockProduct(sku, motivo);
            this.$swal('Completado', 'Estado alterado!', 'success');
            await this.missingProducts();
          }
        });
      } else if (!bool) {
        this.$swal({
          title: 'Quer retirar o produto ' + sku + ' no estado Pendente?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.unlockProduct(sku);
            this.$swal('Completado', 'Estado alterado!', 'success');
            await this.missingProducts();
          }
        });
      }
    },
    async confirmPendentBatch(bool, skus, motivo = 'Bloqueio') {
      const action = bool ? 'bloquear' : 'desbloquear';

      const motivoText = bool ? ` (${motivo})` : '';

      // Solicitar confirmação para todos os produtos selecionados
      this.$swal({
        title: `Quer ${action} ${skus.length} produtos selecionados?`,
        text: `Esta ação irá ${action} os produtos selecionados. ${motivoText}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          for (const sku of skus) {
            if (bool) {
              await this.lockProduct(sku, motivo, false); // Bloquear
            } else {
              await this.unlockProduct(sku, false); // Desbloquear
            }
          }
          if (bool) {
            this.selectedProducts = [];
          } else {
            this.selectedProductsUnlock = [];
          }

          // Mostrar mensagem de sucesso
          this.$swal(
            'Completado',
            `Os produtos foram ${action} com sucesso!`,
            'success'
          );
          await this.missingProducts(); // Atualiza a lista de produtos
        }
      });
    },
    async lockProduct(sku, motivo, swal = true) {
      console.log(sku);
      try {
        await this.$store.dispatch('product_purchase_lock', {
          sku: sku,
          motivo: motivo,
        });
        if (swal) {
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Produto bloqueado',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },

    async unlockProduct(sku, swal = true) {
      console.log(sku);
      try {
        await this.$store.dispatch('product_purchase_unlock', {
          sku: sku,
        });
        if (swal) {
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Produto desbloqueado',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    //Nova Engel
    async downCSVNova() {
      if (this.engel.length == 0) {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Não há produtos para exportar',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let today = new Date();
        let date_i = `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`;
        const prods_engel = [];
        console.log('Nova Engel');
        for (const prod of this.engel) {
          const obj = {
            ean: `'${prod.ean}`,
            qty: prod.qty_order,
          };
          prods_engel.push(obj);
        }
        const fileName = `engel_${date_i}`;
        const exportType = exportFromJSON.types.csv;
        console.log(prods_engel);
        const data = prods_engel;
        exportFromJSON({ data, fileName, exportType, delimeter: ';' });
      }
    },
  },
  async created() {
    this.loading = true;
    this.role = JSON.parse(localStorage.getItem('user')).role;
    if (this.role == 'admin') {
      console.log(this.role);
      this.page = 'compras';
      await this.missingProducts();
    }
    await this.requestProducts();
    await this.getAllSuppliers();
    this.loading = false;
  },
  computed: {
    ...mapGetters(['getPurchaseTransactions']),
    ...mapGetters(['getMissingProducts']),
    ...mapGetters(['getMissingProductsLocked']),
    ...mapGetters(['getMissingProductsDate']),
    ...mapGetters(['getSuppliers']),
    ...mapGetters(['getTransactionsId']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getProductTransactionsRequest']),
    ...mapGetters(['getProductExtraInfo']),
  },
};
</script>
<style scoped>
/* table {
  text-align: center;
} */

/* LOCK TOP */
table {
  margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  text-align: center;
}
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: center;
  text-indent: -0.5em;
}

th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

tr:nth-child(even) th[scope='row'] {
  background-color: #f2f2f2;
}

tr:nth-child(odd) th[scope='row'] {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
}

/* Fixed Headers */

th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

th[scope='row'] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}

th[scope='row'] {
  vertical-align: top;
  color: inherit;
  background-color: inherit;
  background: linear-gradient(
    90deg,
    transparent 0%,
    transparent calc(100% - 0.05em),
    #d6d6d6 calc(100% - 0.05em),
    #d6d6d6 100%
  );
}

table:nth-of-type(2) th:not([scope='row']):first-child {
  left: 0;
  z-index: 3;
  background: linear-gradient(
    90deg,
    #ffede9 0%,
    #ffede9 calc(100% - 0.05em),
    #ccc calc(100% - 0.05em),
    #ccc 100%
  );
}

/* Strictly for making the scrolling happen. */

th[scope='row'] + td {
  min-width: 24em;
}

th[scope='row'] {
  min-width: 20em;
}
/* END LOCK */
#supplierInfo {
  font-size: 10px;
  margin: 0px 0px -10px 0px;
}
#openModal {
  font-size: 10px;
  color: rgb(0, 98, 255);
  text-align: center;
}
#openModal:hover {
  color: white;
}
#openArticle {
  color: rgb(0, 98, 255);
  text-align: center;
}
#openArticle:hover {
  cursor: pointer;
  text-decoration: underline;
}

#openPage {
  color: rgb(0, 0, 0);
  text-align: center;
}
#openPage:hover {
  cursor: pointer;
  text-decoration: underline;
}

.returnOrder {
  margin: 30px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.returnOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.waitAddress {
  margin: 30px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}

.rela {
  margin: 30px 5px 0px 5px;
  background-color: #4f85ad;
  border-color: #4f85ad;
}
.rela:hover {
  background-color: #305c7e;
  border-color: #305c7e;
}

button {
  margin: 10px 5px 0px 5px;
  color: white;
}

.buys {
  text-align: center;
}

img {
  max-width: 100%;
  border-radius: 18px;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}

/* Lock */
#setPendent {
  color: rgb(255, 0, 0);
  font-size: small;
}
#setPendent:hover {
  text-decoration: underline;
  cursor: pointer;
}
/* Lock */
#setWaiting {
  color: rgb(255, 166, 0);
  font-size: small;
}
#setWaiting:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
